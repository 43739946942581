<template>
  <div class="card rounded shadow" style="display: inline-block;">
      <div id="report" class="card-body">
          <div v-if="showProgress" style="text-align:center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="blue"
              indeterminate
            ></v-progress-circular>
          </div>
          <div id="chartContainer" class="" style="text-align:center">
            <pie-chart :chart-id="'myChart'" :chart-data="datacollection" :options="options"></pie-chart>
          </div>
      </div>
  </div>
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';

import PieChart from './PieChart.js';

//import Chart from 'chart.js';

export default {
  components: { 
    //VProgressCircular,
    PieChart 
  },
  data(){
    return{
      message: '',
      items: [],
      tpocors: [],
      debug: null,
      showProgress: false,
      datacollection: {},
      options: {},
      viewButton: '',
      suma: 0
    }
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fetchTpocor();

    let uri = '/api/now';
    this.axios.post(uri).then((response) => {
      var di = response.data.now;

      var query = {};

      this.debug = query;

      //debugger;

      this.searchItems(query);

    });        
  },
  mounted: function() {
  },
  methods: {
    fillData () {
      var labels = this.items.map(i => (i.tcr_nmbre));
      var data = this.items.map(i => (i.num));

      var colors = [];

      var dynamicColors = () => {
        var r = Math.floor(Math.random() * 255);
        var g = Math.floor(Math.random() * 255);
        var b = Math.floor(Math.random() * 255);
        return "rgb(" + r + "," + g + "," + b + ")";
      };

      for (var i in data) {
        colors.push(dynamicColors());
      }      

      this.datacollection = {
        labels: labels,
        datasets: [
          {
            label: 'Cantidad',
            data: data,
            backgroundColor: colors,
            borderColor: 'rgba(200, 200, 200, 0.75)',
            borderWidth: 1
          }
        ]
      };

      this.options = {
        scales: {

        },
        responsive: true,
        title: {
          display: true,
          text: 'Documentos creados por usuario'
        },
        tooltips: {
          mode: 'index',
          intersect: true
        },
        annotation: {
          annotations: [{

          }]
        }
      };
    },
    fetchTpocor(){
      let uri = '/tpocors/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpocors = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.tpocors.length; j++){
            if (this.items[i].tcr_id == this.tpocors[j]._id){
              this.items[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
              j = this.tpocors.length;
            }
          }
        }

      });
    },    
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = String(this.$store.state.company);
        p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/estad-rad-usuario';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;

          this.suma = 0;
          for (var i = 0; i < list.length; i++){
            for (var j = 0; j < this.tpocors.length; j++){
              if (list[i].tcr_id == this.tpocors[j]._id){
                list[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
                j = this.tpocors.length;
              }
            }
            this.suma += list[i].num;

          }

          this.items = list;
          this.fillData();

          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Print');
      p.document.open();
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      var chartContainer = p.document.getElementById('chartContainer');
      var myChart = document.getElementById('myChart');
      p.document.getElementById('myChart').remove();
      const image = p.document.createElement('img');
      image.src = myChart.toDataURL();
      chartContainer.appendChild(image);
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus()
              p.print();
              p.close();
      }, 2000);        
    },
    exportReport(){
      var tableId = 'reporteEstadisticaPqrs';

      var report = [];
      var suma = 0;
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.estado = this.items[i].estado;
        item.tpq_nmbre = this.items[i].tpq_nmbre;
        item.num = this.items[i].num;
        suma += this.items[i].num;
        
        report.push(item);
      }
      
      let item = {};
      item.estado = 'TOTAL';
      item.tpq_nmbre = '';
      item.num = suma;
      report.push(item);

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Estadística de Pqrs"],
        [],
        [],
        ["Estado", "Tipo", "Cantidad"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A8" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>

  @import '../../../public/css/report.css';

  .small {
      width: 400px;
      margin: 40px auto;
    }  
</style>
