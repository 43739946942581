<template>
  <div class="card rounded shadow" style="display: inline-block;">
      <div id="report" class="card-body">
          <div v-if="showProgress" style="text-align:center">
            <v-progress-circular
              :size="50"
              :width="5"
              color="blue"
              indeterminate
            ></v-progress-circular>
          </div>
          <div id="chartContainer" class="" style="text-align:center">
            <bar-chart :chart-id="'myChart'" :chart-data="datacollection" :options="options"></bar-chart>
          </div>
      </div>
  </div>
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';

import BarChart from './BarChart.js';

//import Chart from 'chart.js';

export default {
  components: { 
    //VProgressCircular,
    BarChart 
  },
  data(){
    return{
      message: '',
      items: [],
      tpopqrs: [],
      debug: null,
      dcm_fcharadini: '',
      dcm_fcharadfin: '',
      showProgress: false,
      datacollection: {},
      options: {},
      viewButton: '',
      suma: 0
    }
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fetchTpopqrs();

    let uri = '/api/now';
    this.axios.post(uri).then((response) => {
      var di = response.data.now;

      var query = {};

      this.debug = query;

      //debugger;

      this.searchItems(query);

    });        
  },
  mounted: function() {
  },
  methods: {
      fillData () {
      var labels = this.items.map(i => (i.tpq_nmbre));
      var data = this.items.map(i => (i.num));

      this.datacollection = {
        labels: labels,
        datasets: [
          {
            label: 'Cantidad',
            data: data,
            backgroundColor: 'rgba(230, 138, 0, 0.2)',
            borderColor: 'rgba(230, 138, 0, 1)',
            borderWidth: 1
          }
        ]
      };

      this.options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Cantidad'
                }
            }]
        },
        responsive: true,
        title: {
          display: true,
          text: 'Documentos totales pendientes de respuesta'
        },
        legend: {
					display: false
				},
        tooltips: {
          mode: 'index',
          intersect: true
        },
        annotation: {
          annotations: [{
            type: 'line',
            mode: 'vertical',
            scaleID: 'y-axis-0',
            value: 5,
            borderColor: 'rgb(75, 192, 192)',
            borderWidth: 4,
            label: {
              enabled: false,
              content: 'Test label'
            }
          }]
        }
      };
    },
    fetchTpopqrs(){
      let uri = '/tpopqrs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpopqrs = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.tpopqrs.length; j++){
            if (this.items[i].tpq_id == this.tpopqrs[j]._id){
              this.items[i].tpq_nmbre = this.tpopqrs[j].tpq_nmbre;
              j = this.tpopqrs.length;
            }
          }
        }

      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = String(this.$store.state.company);
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/estad-pendientes-total';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;

          this.suma = 0;
          for (var i = 0; i < list.length; i++){
            list[i].tpq_nmbre = 'Otra solicitud';
            for (var j = 0; j < this.tpopqrs.length; j++){
              if (list[i].tpq_id == this.tpopqrs[j]._id){
                list[i].tpq_nmbre = this.tpopqrs[j].tpq_nmbre;
                j = this.tpopqrs.length;
              }
            }
            this.suma += list[i].num;

          }

          this.items = list;
          this.fillData();

          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Print');
      p.document.open();
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      var chartContainer = p.document.getElementById('chartContainer');
      var myChart = document.getElementById('myChart');
      p.document.getElementById('myChart').remove();
      const image = p.document.createElement('img');
      image.src = myChart.toDataURL();
      chartContainer.appendChild(image);
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus()
              p.print();
              p.close();
      }, 2000);        
    },
    exportReport(){
      var tableId = 'reporteEstadisticaPqrs';

      var report = [];
      var suma = 0;
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.estado = this.items[i].estado;
        item.tpq_nmbre = this.items[i].tpq_nmbre;
        item.num = this.items[i].num;
        suma += this.items[i].num;
        
        report.push(item);
      }
      
      let item = {};
      item.estado = 'TOTAL';
      item.tpq_nmbre = '';
      item.num = suma;
      report.push(item);

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Estadística de Pqrs"],
        [],
        [],
        ["Estado", "Tipo", "Cantidad"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A8" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>

  @import '../../../public/css/report.css';

  .small {
      width: 400px;
      margin: 40px auto;
    }  
</style>
