<template>
  <div class="col-md-12 mx-auto">
    <!-- HOME -->
    <div class="card rounded shadow">
      <div id="home" class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="row text-center">
              <div class="col-md-12">
                <img v-bind:src="$store.state.logo" style="max-width:100%; height:auto;"/>
              </div>
            </div>
            <div class="row text-center">
              <div class="col-md-12" style="font-size:0.9rem">
                <p><a href="http://www.archivosysistemas.com" target="_blank">Archivos y Sistemas AYS</a> - Copyright {{ new Date().getFullYear() }} - <router-link :to="{ name: 'TerminosYCondiciones' }">Términos y condiciones</router-link></p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row text-center">
              <div class="col-md-12">
                <span style="font-family:arial;font-size:10px;color:#E60D51;font-weight:bold;">Acompañado por:</span>
                <br>
                <img src="/images/LogoAPPSCO.svg" alt="">
              </div>
            </div>
            <div class="row text-center">
              <div class="col-md-12">
                <img src="/images/mintic.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END HOME -->
    <br> 

    <!-- DASHBOARD USUARIO-->
    <!--
    <div class="card rounded shadow">
      <div id="dashboard-usuario" class="card-body">
        <h4>Usuario</h4>
        <div class="row">
          <estad-pendientes-usuario class="col-sm-4"></estad-pendientes-usuario>
          <estad-tiempos-restante-usuario class="col-sm-4"></estad-tiempos-restante-usuario>
          <estad-rad-usuario class="col-sm-4"></estad-rad-usuario>
        </div>
      </div>
    </div>
    -->
    <!-- END DASHBOARD -->
    <br>

    <!-- DASHBOARD ENTIDAD-->
    <!--
    <div class="card rounded shadow" v-if="validarTotal">
      <div id="dashboard-entidad" class="card-body">
        <h4>Entidad</h4>
        <div class="row">
          <estad-pendientes-total class="col-sm-4"></estad-pendientes-total>
          <estad-tiempos-restante-total class="col-sm-4"></estad-tiempos-restante-total>
        </div>
      </div>
    </div>
    -->
    <!-- END DASHBOARD -->    

  </div>
</template>

<script>
  //import moment from 'moment';
  import EstadPendientesUsuario from './EstadPendientesUsuario.vue';
  import EstadTiemposRestanteUsuario from './EstadTiemposRestanteUsuario.vue';
  import EstadRadUsuario from './EstadRadUsuario.vue';
  import EstadPendientesTotal from './EstadPendientesTotal.vue';
  import EstadTiemposRestanteTotal from './EstadTiemposRestanteTotal.vue';
  //import EstadTiemposUtilizadoTotal from './EstadTiemposUtilizadoTotal.vue';

  export default {
    components: {
      EstadPendientesUsuario,
      EstadTiemposRestanteUsuario,
      EstadRadUsuario,
      EstadPendientesTotal,
      EstadTiemposRestanteTotal,
      //EstadTiemposUtilizadoTotal
    },
    data() {
      return {
        logo: '',
        uploadPercentage: 0,
        fecha: null
      }
    },
    created: function () {
      /*
      let uri = '/api/now';
      this.axios.post(uri).then((response) => {
        var di = response.data.now;
        this.fecha = moment(di).format('DD/MMM/YYYY hh:mm:ss A');

        var interval = 1000;
        var i = 0;
        var x = setInterval(() => {
          var nd = new Date(di);
          i += interval
          nd.setTime(nd.getTime() + i);
          this.fecha = moment(nd).format('DD/MMM/YYYY hh:mm:ss A');
        }, interval);        
      });   
      */
    },
    mounted: function (){

    },
    methods: {
      validarTotal(){
        switch (this.$store.state.userdata.tus_cdgo){
          case 1,2:
            return true;
            break;
          default:
            return false; 
        }
      }
    } // END METHODS
  }
</script>

<style>

</style>
